import resetPasswordMutation from './reset-password.graphql';
import styles from './styles.module.css';
// @ts-ignore
import dumbPasswords from 'dumb-passwords';
import { PageProps } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { useEffect, useState } from 'react';
import { OnSubmit, useForm } from 'react-hook-form';
import { CustomButton } from 'src/components/formComponents/customButton';
import { CustomInput } from 'src/components/formComponents/customInput';
import { SectionContainer } from 'src/components/sectionContainer';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import { ApolloManager } from 'src/utils/apollo-manager';
import { PageNames } from 'src/utils/common';
import { LinkHelper } from 'src/utils/link-helper';
import { Helmet } from 'react-helmet';

interface ResetPasswordProps {
	onShowLogin: (value: boolean) => void;
}

interface FormValues {
	password: string;
	confirmPassword: string;
}

interface ResetPasswordResult {
	resetPassword: {
		success: boolean;
	};
}

enum SubmitStates {
	NotSubmitted,
	Submitting,
	SuccessfullySubmitted,
	UnsuccessfullySubmitted,
}

const MAX_TEXT_SIZE = 150;
const MIN_PASSWORD_LENGTH = 8;

export const ResetPassword = (props: PageProps & ResetPasswordProps) => {
	const [submitState, setSubmitState] = useState(SubmitStates.NotSubmitted);
	const [submitError, setSubmitError] = useState<string | undefined>(undefined);
	const { register, handleSubmit, errors, setError, clearError, getValues } = useForm<FormValues>();

	// useEffect -> this way we avoid a render of parent while this child is rendering.
	let tellParentToShowLogin = false;
	useEffect(() => {
		if (tellParentToShowLogin) {
			props?.onShowLogin(true);
		}
	});

	const textValidator = (value: string, inputName: string) => {
		if (value.trim().length < 1) {
			return `${inputName} is required`;
		}
		if (value.length >= MAX_TEXT_SIZE) {
			return `${inputName} is too long`;
		}
	};

	const rules = {
		password: {
			required: {
				value: true,
				message: 'Please provide your new password',
			},
			validate: (value: string) => {
				if (dumbPasswords.check(value)) {
					return 'Common and frequently-used passwords are not allowed.';
				}

				if (value.length < MIN_PASSWORD_LENGTH) {
					return `Password length must be at least ${MIN_PASSWORD_LENGTH} characters long`;
				}

				return textValidator(value, 'Password');
			},
		},
		confirmPassword: {
			required: {
				value: true,
				message: 'Please provide your new password',
			},
			validate: (value: string) => {
				const password = getValues('password');
				if (value !== password) {
					return 'Your password and confirmation password don\'t match';
				}

				return textValidator(value, 'Password Confirmation');
			},
		},
	};

	const onHandleSubmit: OnSubmit<FormValues> = async (data) => {
		setSubmitState(SubmitStates.Submitting);
		try {
			const urlParams = new URLSearchParams(props?.location?.search);
			const email = urlParams.get('email');
			const token = urlParams.get('token');
			const result = await ApolloManager.client.mutate<ResetPasswordResult>({
				mutation: resetPasswordMutation,
				variables: {
					email,
					token,
					password: data.password,
				},
			});
			if (result.data?.resetPassword?.success === true) {
				setSubmitState(SubmitStates.SuccessfullySubmitted);
			} else {
				setSubmitState(SubmitStates.UnsuccessfullySubmitted);
			}
		} catch (err) {
			console.error('Error submitting ResetPassword form', err);
			setSubmitState(SubmitStates.UnsuccessfullySubmitted);
		}
	};

	const getButtonSectionJsx = () => {
		switch (submitState) {
			case SubmitStates.NotSubmitted:
				return <CustomButton type="submit" variant="primary" content="Change Password" />;
			case SubmitStates.Submitting:
				return <CustomButton type="reset" variant="primary" disabled content="Submitting" />;
			case SubmitStates.SuccessfullySubmitted:
				return (
					<div className={styles.successMessage}>
						<p>Your password has been changed.</p>
						<p>
							Navigate{' '}
							<AnchorLink className={styles.link} to="/">
								Home
							</AnchorLink>
						</p>
					</div>
				);
			case SubmitStates.UnsuccessfullySubmitted:
				return (
					<div className={styles.unsuccessMessage}>
						<p>Your password could not be changed</p>
						<p>
							Please{' '}
							<AnchorLink
								className={styles.link}
								to={`/${LinkHelper.getPageBaseUrl(PageNames.ContactUs)}`}
							>
								Contact Us
							</AnchorLink>{' '}
							or{' '}
							<span className={styles.link} onClick={() => props?.onShowLogin(true)}>
								Try again
							</span>
						</p>
					</div>
				);
		}
		return null;
	};

	if (props?.location?.search) {
		const urlParams = new URLSearchParams(props?.location?.search);
		if (urlParams.has('email') && urlParams.has('token')) {
			return (
				<SectionContainer withBorderTop>
					<form onSubmit={handleSubmit(onHandleSubmit)} className={styles.form}>
						<div>
							<h2 className={styles.h2}>Change Password</h2>
						</div>
						<CustomInput
							name="password"
							placeHolder="Password"
							type="password"
							errorMessage={errors?.password?.message as string | undefined}
							autoComplete="new-password"
							ref={register(rules.password)}
							disabled={submitState !== SubmitStates.NotSubmitted}
						/>
						<CustomInput
							name="confirmPassword"
							placeHolder="Confirm Password"
							type="password"
							errorMessage={errors?.confirmPassword?.message as string | undefined}
							autoComplete="new-password"
							ref={register(rules.confirmPassword)}
							disabled={submitState !== SubmitStates.NotSubmitted}
						/>
						{getButtonSectionJsx()}
					</form>
				</SectionContainer>
			);
		}
	}

	tellParentToShowLogin = true;
	return (
		<SectionContainer>
			<p className={styles.message}>
				If you would like to reset your password, please use "Forgot password" feature on the login
				page.
			</p>
		</SectionContainer>
	);
};

export default (props: PageProps) => {
	const [showLogin, setShowLogin] = useState(false);
	return (
		<DefaultLayoutTemplate showLogin={showLogin}>
			<Helmet>
				<title>Reset Password</title>
			</Helmet>
			<ResetPassword {...props} onShowLogin={(value: boolean) => setShowLogin(value)} />
		</DefaultLayoutTemplate>
	);
};
